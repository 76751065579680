import * as React from "react"

function Logo(props) {
  return (
    <svg width="220" height="76" viewBox="0 0 220 76" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_108_242)">
        <rect width="220" height="75" transform="translate(0 0.36499)" fill="white" />
        <g clip-path="url(#clip1_108_242)">
          <mask id="mask0_108_242" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="-149" y="-135" width="518" height="401">
            <path d="M-149 -134.5H368.073V265.5H-149V-134.5Z" fill="white" />
          </mask>
          <g mask="url(#mask0_108_242)">
            <path d="M63.8331 28.9404L73.2579 22.1149L85.2525 20.5221L89.5666 16.4476L97.6942 13.3019L97.8013 18.1561L100.643 13.3842L106.059 8.63651L113.28 11.3319L122.29 14.1895L131.532 17.2594L163.909 32.8783L166.427 32.7868C166.427 17.1653 140.434 4.50122 108.37 4.50122C76.3062 4.50122 50.3135 17.1653 50.3135 32.7868C50.3135 34.2123 50.5296 35.6123 50.9474 36.981L52.6259 36.9202L63.8331 28.9404Z" fill="#DCA832" />
            <path d="M131.963 16.3763L122.591 13.2547L113.628 10.4135L105.859 7.51288L99.8862 12.7488L98.7104 14.724L98.6477 11.8835L89.0394 15.6024L84.8154 19.5913L72.8894 21.1756L63.2706 28.1416L51.8924 36.1972C51.5751 35.0175 51.4054 33.8181 51.4054 32.624C51.4054 17.3874 76.96 4.99197 108.37 4.99197C139.361 4.99197 164.648 17.0586 165.318 32.0155L131.963 16.3763ZM144.363 30.5233L130.449 19.4345L121.975 15.1168L121.987 15.1201L131.169 18.1697L140.128 22.5063L151.227 29.1756L144.363 30.5233ZM166.638 32.624C166.638 16.6665 140.499 3.68478 108.37 3.68478C76.2406 3.68478 50.101 16.6665 50.101 32.624C50.101 34.092 50.3223 35.5671 50.7577 37.0077L51.4779 38.0345L73.6213 42.3129L70.1721 27.9194L72.3436 23.9907L73.6363 23.0541L85.0086 21.5436L77.3615 43.475L93.4352 39.1358L93.0944 18.2129L96.2961 14.8965L96.7511 14.7201L96.9026 21.5887L97.9341 19.8573L97.9537 20.8031L95.3906 28.7822L99.6192 28.1861L101.291 25.4384L106.49 25.0181L109.859 15.5135L106.86 9.9789L111.48 11.7031L128.092 21.1802L147.064 36.7416L153.673 39.6828L164.639 34.3704L166.638 32.624Z" fill="#494A4D" />
            <path d="M18.0481 46.5537V56.0518C18.0481 59.657 16.2174 60.389 11.7831 60.389C7.8385 60.389 5.31189 60.0413 5.31189 56.8753V54.8988H8.86938V55.9237C8.86938 57.7171 8.86938 58.1381 11.7831 58.1381C14.0003 58.1381 14.3352 57.7354 14.3352 56.0518V46.5537H18.0481Z" fill="#494A4D" />
            <path d="M24.9848 52.1539H26.7123L33.1574 46.5539H37.721L29.8833 53.252L38.5194 60.2794H33.8279L26.7123 54.4043H24.9848V60.2794H21.2719V46.5539H24.9848V52.1539Z" fill="#494A4D" />
            <path d="M55.7437 46.5537L49.5042 60.2792H43.9098L37.7219 46.5537H41.5124L46.6688 58.0831H46.7465L51.9022 46.5537H55.7437Z" fill="#494A4D" />
            <path d="M62.3712 53.3979H71.0857V54.5515H62.3712V59.0894H71.4722V60.2789H60.4374V48.073H71.4722V49.2626H62.3712V53.3979Z" fill="#494A4D" />
            <path d="M88.6441 60.2792H85.4731L78.2537 51.9707L77.0942 50.5981L76.5007 49.921L75.9334 49.2439H75.8818L75.9079 49.7014V50.1589V51.0739V60.2792H73.9741V48.0726H77.1458L83.4101 55.3563L85.0605 57.2596L85.8851 58.2112L86.6848 59.1628H86.7358L86.7103 58.7053V58.2478V57.3328V48.0726H88.6441V60.2792Z" fill="#494A4D" />
            <path d="M105.584 54.2219C105.636 54.7336 105.636 54.7885 105.636 55.5395C105.636 57.7722 105.533 58.3944 104.992 59.0532C104.579 59.6016 103.883 59.9683 102.904 60.1879C102.207 60.3343 100.814 60.4075 98.6489 60.4075C95.2716 60.4075 94.0605 60.2245 92.8482 59.5107C92.0752 59.0532 91.5072 58.0826 91.4301 57.0585C91.4047 56.5094 91.4047 56.3996 91.3785 54.0199V53.5075C91.3785 51.312 91.5855 50.3787 92.2038 49.482C92.7711 48.6951 93.7504 48.2375 95.22 48.0539C95.7874 47.9807 96.8959 47.9447 98.4687 47.9447C102.336 47.9447 103.006 48.0179 104.192 48.548C104.836 48.8415 105.326 49.4088 105.481 50.031C105.559 50.4702 105.584 50.7081 105.584 51.6225H103.677C103.651 49.2624 103.367 49.1343 98.2108 49.1343C95.2461 49.1343 94.2923 49.3349 93.802 50.1035C93.4155 50.7081 93.3123 51.5127 93.3123 54.0022C93.3123 57.1859 93.3639 57.6624 93.7765 58.2656C94.3178 59.0343 95.3748 59.2362 98.959 59.2362C103.341 59.2362 103.702 58.9983 103.702 56.1245C103.702 55.997 103.702 55.7225 103.677 55.4107H98.2108V54.2219H105.584Z" fill="#494A4D" />
            <path d="M110.175 60.2791H108.241V48.0725H110.175V60.2791Z" fill="#494A4D" />
            <path d="M128.093 60.2792H124.922L117.703 51.9707L116.543 50.5981L115.95 49.921L115.383 49.2439H115.331L115.357 49.7014V50.1589V51.0739V60.2792H113.423V48.0726H116.595L122.859 55.3563L124.509 57.2596L125.334 58.2112L126.134 59.1628H126.185L126.159 58.7053V58.2478V57.3328V48.0726H128.093V60.2792Z" fill="#494A4D" />
            <path d="M133.303 53.3979H142.017V54.5515H133.303V59.0894H142.404V60.2789H131.369V48.073H142.404V49.2626H133.303V53.3979Z" fill="#494A4D" />
            <path d="M146.839 53.3979H155.554V54.5515H146.839V59.0894H155.94V60.2789H144.906V48.073H155.94V49.2626H146.839V53.3979Z" fill="#494A4D" />
            <path d="M165.97 54.1849C168.806 54.1849 169.709 53.6542 169.709 52.0072C169.709 50.7444 169.605 50.2137 169.245 49.8666C168.858 49.4634 168.007 49.2627 166.64 49.2627H160.376V54.1849H165.97ZM158.442 48.0725H166.615C170.302 48.0725 171.643 49.0058 171.643 51.5862C171.643 53.8006 170.971 54.5326 168.677 54.8072V54.8255C170.456 54.8987 171.436 55.7405 171.41 57.1862V60.2791H169.476V57.4974C169.528 55.9418 168.548 55.3196 166.228 55.3745H160.376V60.2791H158.442V48.0725Z" fill="#494A4D" />
            <path d="M176.31 60.2791H174.376V48.0725H176.31V60.2791Z" fill="#494A4D" />
            <path d="M194.229 60.2792H191.058L183.838 51.9707L182.679 50.5981L182.085 49.921L181.518 49.2439H181.466L181.493 49.7014V50.1589V51.0739V60.2792H179.559V48.0726H182.73L188.995 55.3563L190.645 57.2596L191.47 58.2112L192.269 59.1628H192.32L192.295 58.7053V58.2478V57.3328V48.0726H194.229V60.2792Z" fill="#494A4D" />
            <path d="M211.169 54.2219C211.22 54.7336 211.22 54.7885 211.22 55.5395C211.22 57.7722 211.117 58.3944 210.576 59.0532C210.163 59.6016 209.467 59.9683 208.488 60.1879C207.792 60.3343 206.399 60.4075 204.233 60.4075C200.856 60.4075 199.645 60.2245 198.433 59.5107C197.66 59.0532 197.092 58.0826 197.015 57.0585C196.989 56.5094 196.989 56.3996 196.963 54.0199V53.5075C196.963 51.312 197.17 50.3787 197.788 49.482C198.356 48.6951 199.335 48.2375 200.805 48.0539C201.372 47.9807 202.48 47.9447 204.053 47.9447C207.92 47.9447 208.591 48.0179 209.777 48.548C210.421 48.8415 210.911 49.4088 211.066 50.031C211.143 50.4702 211.169 50.7081 211.169 51.6225H209.261C209.235 49.2624 208.952 49.1343 203.795 49.1343C200.831 49.1343 199.877 49.3349 199.387 50.1035C199 50.7081 198.897 51.5127 198.897 54.0022C198.897 57.1859 198.948 57.6624 199.361 58.2656C199.902 59.0343 200.959 59.2362 204.544 59.2362C208.926 59.2362 209.287 58.9983 209.287 56.1245C209.287 55.997 209.287 55.7225 209.261 55.4107H203.795V54.2219H211.169Z" fill="#494A4D" />
            <path d="M57.0908 69.922C58.8177 69.922 59.0971 69.7605 59.0971 68.7475C59.0971 67.9677 58.9809 67.6716 58.6192 67.5455C58.3855 67.4651 58.2921 67.4566 57.3709 67.4475H55.0141V69.922H57.0908ZM54.1392 66.8645H57.3944C58.3972 66.8645 58.6773 66.8913 59.0273 67.0076C59.6684 67.2233 59.972 67.7605 59.972 68.7109C59.972 69.6082 59.6808 70.1102 59.0273 70.352C58.6656 70.4867 58.6068 70.4867 57.2658 70.505H55.0141V72.8448H54.1392V66.8645Z" fill="#494A4D" />
            <path d="M61.8988 69.4732H65.8415V70.0379H61.8988V72.2621H66.0171V72.8445H61.0239V66.8647H66.0171V67.4471H61.8988V69.4732Z" fill="#494A4D" />
            <path d="M70.2535 67.4116L68.5508 71.043H71.9803L70.2535 67.4116ZM68.3053 71.5803L67.7099 72.845H66.7769L69.6234 66.8646H70.86L73.7646 72.845H72.8434L72.2362 71.5803H68.3053Z" fill="#494A4D" />
            <path d="M75.435 69.4822H76.2054L79.3901 66.8646H80.5332L76.9744 69.7692L80.9524 72.845H79.7628L76.2054 70.0835H75.435V72.845H74.5601V66.8646H75.435V69.4822Z" fill="#494A4D" />
            <path d="M87.4653 69.922C89.1921 69.922 89.4716 69.7605 89.4716 68.7475C89.4716 67.9677 89.3554 67.6716 88.9937 67.5455C88.7599 67.4651 88.6666 67.4566 87.7454 67.4475H85.3885V69.922H87.4653ZM84.5137 66.8645H87.7689C88.7717 66.8645 89.0518 66.8913 89.4017 67.0076C90.0428 67.2233 90.3464 67.7605 90.3464 68.7109C90.3464 69.6082 90.0552 70.1102 89.4017 70.352C89.04 70.4867 88.9813 70.4867 87.6403 70.505H85.3885V72.8448H84.5137V66.8645Z" fill="#494A4D" />
            <path d="M92.2732 69.4732H96.2158V70.0379H92.2732V72.2621H96.3915V72.8445H91.3983V66.8647H96.3915V67.4471H92.2732V69.4732Z" fill="#494A4D" />
            <path d="M100.931 69.8587C102.214 69.8587 102.622 69.5993 102.622 68.7921C102.622 68.1738 102.576 67.913 102.413 67.7431C102.238 67.5457 101.853 67.4476 101.234 67.4476H98.3996V69.8587H100.931ZM97.5247 66.8646H101.223C102.891 66.8646 103.498 67.3215 103.498 68.5862C103.498 69.6705 103.194 70.0293 102.156 70.164V70.1725C102.961 70.2084 103.404 70.6208 103.393 71.3293V72.8444H102.518V71.4816C102.541 70.7195 102.097 70.4149 101.048 70.4417H98.3996V72.8444H97.5247V66.8646Z" fill="#494A4D" />
            <path d="M105.611 69.5275H109.402V70.0922H105.611V72.8445H104.736V66.8647H109.507V67.4471H105.611V69.5275Z" fill="#494A4D" />
            <path d="M111.632 67.6714C111.399 67.8773 111.293 68.1466 111.247 68.6485C111.235 68.774 111.235 68.774 111.235 69.8498C111.235 71.0877 111.235 71.0877 111.305 71.4283C111.387 71.8407 111.596 72.0917 111.959 72.1995C112.273 72.2975 112.6 72.3243 113.638 72.3243C115.062 72.3243 115.446 72.2707 115.715 72.0381C115.948 71.8315 116.053 71.5623 116.1 71.0609C116.111 70.9348 116.111 70.9348 116.111 69.8498C116.111 68.6217 116.111 68.6217 116.042 68.2812C115.96 67.8688 115.75 67.6178 115.388 67.51C115.073 67.4119 114.735 67.3845 113.708 67.3845C112.285 67.3845 111.9 67.4387 111.632 67.6714ZM115.785 66.9989C116.45 67.2145 116.8 67.6538 116.94 68.4243C116.975 68.693 116.987 68.8368 116.987 69.5989C116.987 70.9975 116.975 71.2034 116.846 71.6609C116.706 72.1904 116.368 72.5309 115.808 72.71C115.318 72.8714 114.921 72.9073 113.778 72.9073C112.413 72.9073 112.04 72.8714 111.538 72.71C110.979 72.5217 110.652 72.1904 110.501 71.6609C110.372 71.1949 110.361 70.9975 110.361 69.608C110.361 68.3623 110.43 68.0119 110.746 67.5544C111.002 67.1956 111.457 66.9629 112.11 66.8649C112.39 66.8289 112.997 66.8015 113.673 66.8015C114.863 66.8015 115.33 66.8466 115.785 66.9989Z" fill="#494A4D" />
            <path d="M121.666 69.8587C122.95 69.8587 123.358 69.5993 123.358 68.7921C123.358 68.1738 123.312 67.913 123.148 67.7431C122.973 67.5457 122.588 67.4476 121.97 67.4476H119.135V69.8587H121.666ZM118.26 66.8646H121.958C123.626 66.8646 124.233 67.3215 124.233 68.5862C124.233 69.6705 123.93 70.0293 122.892 70.164V70.1725C123.696 70.2084 124.139 70.6208 124.128 71.3293V72.8444H123.253V71.4816C123.276 70.7195 122.833 70.4149 121.783 70.4417H119.135V72.8444H118.26V66.8646Z" fill="#494A4D" />
            <path d="M133.999 72.8446H133.124V68.057V67.6982V67.5191L133.136 67.3394H133.113C133.078 67.4296 133.042 67.4923 133.007 67.5636C132.961 67.6446 132.95 67.6623 132.833 67.8956L130.185 72.8446H129.298L126.65 67.9577L126.499 67.6806L126.428 67.5368L126.358 67.4028H126.335L126.347 67.5636V67.7341V68.057V72.8446H125.472V66.8649H126.988L129.064 70.7374L129.391 71.3564L129.555 71.6701L129.718 71.9838H129.741L129.905 71.6701L130.069 71.3564L130.395 70.7374L132.46 66.8649H133.999V72.8446Z" fill="#494A4D" />
            <path d="M138.411 67.4116L136.709 71.043H140.138L138.411 67.4116ZM136.463 71.5803L135.868 72.845H134.935L137.781 66.8646H139.018L141.923 72.845H141.001L140.394 71.5803H136.463Z" fill="#494A4D" />
            <path d="M149.356 72.8446H147.921L144.655 68.774L144.13 68.1015L143.861 67.7694L143.604 67.4387H143.581L143.593 67.6623V67.8864V68.3348V72.8446H142.718V66.8649H144.153L146.987 70.4328L147.734 71.3649L148.108 71.8315L148.469 72.2975H148.493L148.48 72.0734V71.8498V71.4008V66.8649H149.356V72.8446Z" fill="#494A4D" />
            <path d="M156.87 70.8455C156.87 71.9122 156.824 72.1272 156.498 72.441C156.182 72.7455 155.646 72.8717 154.643 72.8985C154.351 72.907 153.884 72.907 153.476 72.907C152.379 72.907 151.936 72.8357 151.446 72.5848C151.085 72.3965 150.805 72.0024 150.723 71.571C150.653 71.2129 150.595 70.4782 150.595 69.8586C150.595 68.5501 150.595 68.4155 150.677 68.0835C150.805 67.4743 151.399 67.0174 152.251 66.8913C152.683 66.8285 153.196 66.8018 154.246 66.8018C155.226 66.8018 155.587 66.8469 155.984 67.0174C156.392 67.1867 156.661 67.537 156.72 67.9678C156.742 68.1553 156.754 68.2717 156.777 68.6933H155.903C155.891 68.0567 155.856 67.8776 155.715 67.6893C155.518 67.4475 155.179 67.375 154.222 67.375C153.137 67.375 152.438 67.4207 152.158 67.5096C151.668 67.6802 151.516 68.0031 151.516 68.9266C151.516 72.2978 151.528 72.3246 153.674 72.3246C155.774 72.3246 156.008 72.2083 156.008 71.1861V70.8455H156.87Z" fill="#494A4D" />
            <path d="M158.821 69.4732H162.763V70.0379H158.821V72.2621H162.939V72.8445H157.946V66.8647H162.939V67.4471H158.821V69.4732Z" fill="#494A4D" />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_108_242">
          <rect width="220" height="75" fill="white" transform="translate(0 0.36499)" />
        </clipPath>
        <clipPath id="clip1_108_242">
          <rect width="517.073" height="400" fill="white" transform="translate(-149 -134.5)" />
        </clipPath>
      </defs>
    </svg> 
  )
}

export default Logo
